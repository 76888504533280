import React from "react";
import styled from "styled-components";
import officeImage from "../assets/images/about-img.jpg.png";

const AboutContainer = styled.section`
  padding: 80px 20px;
  background-color: #3b3b3b;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    padding: 60px 20px 0 20px;
  }
`;

const AboutContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: left; /* Alinear contenido a la izquierda */
  margin: 0 4em 0 4em;

  @media (max-width: 900px) {
    text-align: center;
    flex-direction: column;
    margin: 0;
  }
`;

const AboutTextContainer = styled.div`
  flex: 1;
  padding: 20px;

  @media (max-width: 900px) {
    text-align: left;
    padding: 20px 0 4em 0;
  }
`;

const AboutImage = styled.img`
  flex: 1;
  max-width: 100%;
  height: auto;
`;

const AboutTitle = styled.h2`
  font-size: 27px;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2em;

  /* Subrayado completo */
  &::after {
    content: "";
    display: block;
    width: 75%; /* Línea completa */
    height: 3px;
    background-color: #cccccc;
    margin: 10px auto; /* Centrado */
  }

  span {
    color: #00c6d7; /* Color azul para resaltar "Nosotros" */
  }

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

const AboutSubtitle = styled.h3`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;

  span {
    color: #337ab7; /* Azul para "MONACO IT SERVICES" */
  }

  @media (max-width: 900px) {
    font-size: 1.75em;
  }
`;

const AboutText = styled.p`
  color: #f4f4f4;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  max-width: 800px;

  @media (max-width: 900px) {
    font-size: 16px;
    max-width: 100%;
    text-align: justify;
  }
`;

const AboutTextUp = styled.h1`
  color: #f4f4f4;
  font-size: 20px;
  line-height: 2;
  max-width: 800px;
  font-weight: 700;

  @media (max-width: 900px) {
    line-height: 1.5;
    font-size: 20px;
    padding-bottom: 10px;
    padding-right: 0px;
  }
`;

export const About = () => {
  return (
    <AboutContainer id="about">
      <AboutTitle>
        SOBRE <span>NOSOTROS</span>
      </AboutTitle>
      <AboutContent>
        <AboutImage src={officeImage} alt="Oficina" />
        <AboutTextContainer>
          <AboutSubtitle>
            SOMOS <span>MONACO IT SERVICES</span>
          </AboutSubtitle>
          <AboutTextUp>
            Una empresa de desarrollo de software enfocada en StartUps
          </AboutTextUp>
          <AboutText>
            MONACO IT SERVICES nació tras haber sido galardonados en el Apple
            Swift Challenge, siendo reconocidos como ganadores dintinguidos y el primer chileno en ganar
            este prestigioso premio. Especializándonos en soluciones
            tecnológicas innovadoras para startups, ofrecemos un enfoque
            personalizado en el desarrollo de aplicaciones móviles, plataformas
            web y tecnologías de inteligencia artificial. Nos destacamos por
            combinar creatividad y tecnología de vanguardia, creando productos
            escalables y únicos. Nuestro compromiso es impulsar el crecimiento
            de nuestros clientes, integrando tecnología avanzada para llevar sus
            proyectos al siguiente nivel.
          </AboutText>
        </AboutTextContainer>
      </AboutContent>
    </AboutContainer>
  );
};
